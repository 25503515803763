import { Box, Typography } from '@mui/material';

import ThreeStarSvg from '@public/assets/svg/Three_Star.svg';
import ThreeStarBigSvg from '@public/assets/svg/Three_Star_Big.svg';
import { TOneLineDeatil } from '@utils/types';

import { useStyles } from './useStyles';
import theme from 'src/style-system/theme';
import { Carousel } from 'react-responsive-carousel';

interface IOneLineDetail {
  data: TOneLineDeatil[];
}

const OneLineDetail = ({ data }: IOneLineDetail) => {
  const classes = useStyles();
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.svgWrapper}>
        <ThreeStarSvg />
      </Box>
      <Box
        sx={{
          [theme.breakpoints.up(1380)]: {
            display: 'none',
          },
        }}
      >
        <Carousel
          autoPlay
          infiniteLoop
          swipeable={false}
          interval={1500}
          showStatus={false}
          showThumbs={false}
          showArrows={false}
          axis="vertical"
          renderIndicator={() => {
            return (
              <li
                style={{
                  display: 'none',
                }}
              />
            );
          }}
        >
          {data.map((item) => {
            return (
              <Box className={classes.container} key={item.id}>
                {item.textArr?.map((text, index) => (
                  <Typography
                    variant="body1"
                    color={index % 2 ? 'turmeric.main' : 'whitePepper.main'}
                    textAlign="center"
                    sx={{ flexShrink: 0 }}
                    key={`${text}-${index}`}
                  >
                    {text}
                  </Typography>
                ))}
                {item?.text ? (
                  <Typography
                    variant="body1"
                    color="whitePepper.main"
                    textAlign="center"
                    sx={{ flexShrink: 0 }}
                  >
                    {item?.text}
                  </Typography>
                ) : null}
              </Box>
            );
          })}
        </Carousel>
      </Box>
      <Box className={classes.innerContainer}>
        {data.map((item) => {
          return (
            <Box className={classes.textSvgWrapper} key={item.id}>
              <ThreeStarBigSvg />
              {item.textArr?.map((text, index) => (
                <Typography
                  variant="body1"
                  color={index % 2 ? 'turmeric.main' : 'whitePepper.main'}
                  textAlign="center"
                  sx={{ flexShrink: 0 }}
                >
                  {text}
                </Typography>
              ))}
              {item?.text ? (
                <Typography
                  variant="body1"
                  color="whitePepper.main"
                  textAlign="center"
                  sx={{ flexShrink: 0 }}
                >
                  {item?.text}
                </Typography>
              ) : null}
            </Box>
          );
        })}
        <ThreeStarBigSvg />
      </Box>
      <Box
        className={classes.svgWrapper}
        sx={{
          [theme.breakpoints.up(1380)]: {
            display: 'none',
          },
        }}
      >
        <ThreeStarSvg />
      </Box>
    </Box>
  );
};

export default OneLineDetail;
