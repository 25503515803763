import { useState, memo } from 'react';
import { useRouter } from 'next/router';
import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import theme from 'src/style-system/theme';
import { usePersistentPromoCodeDetails } from 'store/promoCode';
import useAuth from '@utils/useAuth';
import { AppRoutes } from '@utils/appRoutes';

interface IAnnouncementBanner {
  message?: string;
  mobileMessage?: string;
  children?: React.ReactNode;
  isMobileMessage?: boolean;
  shouldHideCloseButton?: boolean;
}

const AnnouncementBanner = ({
  message,
  mobileMessage,
  children,
  isMobileMessage = true,
  shouldHideCloseButton = false,
}: IAnnouncementBanner) => {
  const [display, setDisplay] = useState<string>('flex');
  const router = useRouter();

  const { authState } = useAuth();
  const { failedSubscriptionId } = authState?.user || {};

  const promoCodeDetails = usePersistentPromoCodeDetails();

  const handleClickCloceIcon = () => {
    setDisplay('none');
  };

  const onHandleUpdate = () => {
    router.push({
      pathname: `${AppRoutes.SUBSCRIPTIONS}/${failedSubscriptionId}`,
      query: { isScrollToReplaceCard: true },
    });
  };

  return (
    <Box
      bgcolor={failedSubscriptionId ? 'red.main' : 'turmeric.main'}
      sx={{
        padding: { xs: '6px 15px', md: '7px 25px' },
        display: display,
        alignItems: 'center',
        justifyContent: shouldHideCloseButton ? 'center' : 'space-between',
        columnGap: '5px',
        [theme.breakpoints.down('md')]: {
          display: isMobileMessage || children ? display : 'none',
        },
      }}
    >
      {/* Below box only used to display msg in between and close icon at end */}
      {shouldHideCloseButton ? null : <Box width="2px" />}
      {failedSubscriptionId ? (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="body2" align="left" color="#FFFFFF" sx={{}}>
              There seems to be an issue with your subscription payment method.
              <Typography
                component="span"
                variant="body2"
                align="center"
                ml="10px"
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={onHandleUpdate}
              >
                Update Now
              </Typography>
            </Typography>
          </Box>
          <Box width="2px" />
        </>
      ) : (
        <>
          {children ? (
            children
          ) : (
            <>
              <Typography
                variant="body2"
                align="center"
                color="dark3.main"
                sx={{
                  [theme.breakpoints.down('md')]: {
                    display: 'none',
                  },
                }}
              >
                {promoCodeDetails?.promoText ||
                  message ||
                  'Limited time offer - 15% off + Free Shipping with code WELCOME15'}
              </Typography>
              {isMobileMessage ? (
                <Typography
                  variant="body2"
                  align="center"
                  color="dark3.main"
                  sx={{
                    [theme.breakpoints.up('md')]: {
                      display: 'none',
                    },
                  }}
                >
                  {promoCodeDetails?.promoText ||
                    mobileMessage ||
                    'Limited time offer - 15% off + Free Shipping with code WELCOME15'}
                </Typography>
              ) : null}
            </>
          )}
          {shouldHideCloseButton ? null : (
            <CloseIcon
              sx={{ color: 'dark3.main', cursor: 'pointer' }}
              onClick={handleClickCloceIcon}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default memo(AnnouncementBanner);
